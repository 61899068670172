import * as React from "react"
import { useState } from "react"
import AppButton from "../shared/components/Button/AppButton"
import QuestionContent from "./components/QuestionContent/QuestionContent"
import { questionsUseStyles } from "./Questions.styles"
import { Container, Grid, Typography } from "@material-ui/core"

const Questions = ({
  classes,
  handleNavigateNextSection,
  questions,
  answers,
  setAnswers,
}) => {
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = async () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleFinish = () => {
    handleNavigateNextSection()
  }

  const handleBack = async () => {
    setActiveStep(prevActiveStep =>
      prevActiveStep !== 0 ? prevActiveStep - 1 : 0
    )
  }

  const handleResponseChange = key => $event => {
    const value =
      $event && $event.target && $event.target.value ? $event.target.value : ""
    setAnswers(prev => ({ ...prev, [key]: value }))

    if (value && hasNext) {
      setTimeout(() => handleNext(), 500)
    }
  }

  const steps = questions.map((_, index) => index + 1)
  const hasNext = activeStep + 1 <= questions.length - 1
  const isCurrentAnswered = Object.entries(answers).find(([key, val]) => questions[activeStep].key === key && !!val);
  const isLastQuestion = activeStep === questions.length - 1
  const isFirstQuestion = activeStep === 0
  const hasEmptyAnswers = Object.entries(answers).find(([_, val]) => !val)

  return (
    <Container maxWidth="sm" className={classes.root}>
      <QuestionContent
        question={questions[activeStep]}
        value={questions[activeStep] ? answers[questions[activeStep].key] : ""}
        handleChange={handleResponseChange}
      />
      <Grid container className={classes.buttonContainer}>
        <Grid>
          {!isFirstQuestion && (
            <AppButton handleClick={handleBack} text="Back" />
          )}
        </Grid>
        <Grid>
          <Typography variant="body1">
            {activeStep + 1} / {steps.length}
          </Typography>
        </Grid>
        <Grid>
          {isLastQuestion && !hasEmptyAnswers && (
            <AppButton handleClick={handleFinish} text="Finish Up" />
          )}
          {!isLastQuestion && isCurrentAnswered && (
            <AppButton handleClick={handleNext} text="Next" />
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default questionsUseStyles(Questions)
