// @ts-ignore
import { IAnswer, IQuestion } from "../typings"
import _ from "lodash";
import { mind, heart } from "../variables/charts"
import Cookies from 'universal-cookie'

const cookies = new Cookies();

export class ScoringUtil {
  static calculateShineScoreDetails(questionList: IQuestion[], answers: IAnswers): IShineScore {
    // Bring all the Weights for the questions
    const rawWeights = this.getRawWeights(questionList)
    // Bring the points for the answers by group ie. a... b... c...
    const pointedAnswers = this.getPointedAnswers(rawWeights, answers)
    // Compose points by group
    const pointedGroups = this.getPointedGroups(pointedAnswers)
    // Compose hearth and mind scores
    const { hearth, mind } = this.getHearthAndMindPoints(pointedGroups)
    // Calculate Shine Scale
    const shineScale = this.getShineScaleScore(hearth, mind)

    return {
      hearth,
      mind,
      shineScale,
      pointedGroups,
      // additional attributes TBD
    }
  }

  static getRawWeights(questions: IQuestion[]): IAnswerPoints {
    return questions.reduce(
      (accumPoints: IAnswerPoints, question: IQuestion) => {
        return {
          ...accumPoints,
          ...Object.entries(question.answers).reduce(
            (inner: IAnswerPoints, [_, value]: [string, IAnswer]) => {
              return {
                ...inner,
                [value.key]: value.weight,
              }
            },
            {},
          ),
        }
      },
      {},
    )
  }

  static getPointedAnswers(
    rawWeights: IAnswerPoints,
    answers: IAnswers,
  ): IAnswerPoints {
    // the value of the answer relate to the key of the AnswerPoints Object.
    return Object.entries(answers).reduce(
      (accum: IAnswerPoints, [key, value]) => {
        return {
          ...accum,
          [key]: rawWeights[value],
        }
      },
      {},
    )
  }

  static getPointedGroups(pointedAnswers: IGroupPoints): IGroupPoints {
    return Object.entries(pointedAnswers).reduce(
      (accum: IGroupPoints, [key, value]: [string, number]) => {
        // key ie... c1
        // value ie... 4.43
        const [gLetter, _] = key.split("")
        const prevValue = accum[gLetter] ? accum[gLetter] : 0

        // Means that both questions for the group have been correctly answered, preset value.
        const isGroupCorrect = prevValue !== 0 && value !== 0
        if (isGroupCorrect) {
          return {
            ...accum,
            [gLetter]: 14.2858, // TODO: arbitrary, should be in a different file, or come from
          }
        }
        //picking the one that's not 0
        const isCurrentValueGreater = value > prevValue
        return {
          ...accum,
          [gLetter]: isCurrentValueGreater ? value : prevValue,
        }
      },
      {},
    )
  }

  static getHearthAndMindPoints(pointedGroups: IGroupPoints) {
    const heartGroups = ["j", "k", "l", "m", "n", "o", "p"]
    const mindGroups = ["c", "d", "e", "f", "g", "h", "i"]

    // TODO: need some polishing.
    return {
      hearth: heartGroups.reduce((accum, groupKey) => accum + pointedGroups[groupKey], 0),
      mind: mindGroups.reduce((accum, groupKey) => accum + pointedGroups[groupKey], 0),
    }
  }

static getChartPoints = (pointedGroups: IGroupPoints) => {
    const recordArray = pointedGroups ? _.orderBy(Object.entries(pointedGroups)) : [];
    const scores = [
      {
        c: 0,
        d: 0,
        e: 0,
        f: 0,
        g: 0,
        h: 0,
        i: 0
      },
      {
        j: 0,
        k: 0,
        l: 0,
        m: 0,
        n: 0,
        o: 0,
        p: 0
      }
    ];
    for (let i = 0; i < recordArray.length; i++) {
      if(i < 7) {
        if(recordArray[i][1] === 0) {
          scores[0][recordArray[i][0]] += 0;
        } else if (recordArray[i][1] === 14.2858) {
          scores[0][recordArray[i][0]] += 3;
        } else if (recordArray[i][1] > 5.43) {
          scores[0][recordArray[i][0]] += 2;
        } else {
          scores[0][recordArray[i][0]] += 1;
        }
      } else {
        if(recordArray[i][1] === 0) {
          scores[1][recordArray[i][0]] += 0;
        } else if (recordArray[i][1] === 14.2858) {
          scores[1][recordArray[i][0]] += 3;
        } else if (recordArray[i][1] > 5.43) {
          scores[1][recordArray[i][0]] += 2;
        } else {
          scores[1][recordArray[i][0]] += 1;
        }
      }
    }
    return scores;
  };

  static getShineScaleScore(hearth: number, mind: number) {
    return (hearth + mind) / 2
  }

  static getShineTitle(shineScale: number) {
    if (shineScale >= 90) return "Shining!"
    if (shineScale <= 89 && shineScale >= 80) return "Shining often!";
    if (shineScale <= 79 && shineScale >= 60) return "Shining occasionally!"
    if (shineScale >= 40 && shineScale <= 59) return "Sparking!"
    if (shineScale <= 39) return "Ignited!"
  }

  static getShineFeedbackParagraph(shineScale: number, emailVersion: boolean = false) {
    if (shineScale >= 90) {
      return (emailVersion)
        ? "A 90% or above score is not common. You shine more than most and it’s likely " +
          "because you’re someone who is a high performer with good empathy skills and a " +
          "strong EQ (emotional quotient.) It’s also likely great things happen to you often, " +
          "which happens as you attract unintentional perfection."

        : "You’re part of a small group of people who score this high! " +
          "So be proud, but keep creating sparks. It’s likely you’re a high performer " +
          "with good empathy skills and a strong EQ. It’s also likely great things " +
          "happen to you often, which sometimes feel like unintentional perfection. You are shining. Shine on."
    }
    if (shineScale <= 89 && shineScale >= 80) {
      return (emailVersion)
        ? "Your shine is above average. You create sparks more than most and likely have moments " +
          "in your life where shine is abundant, but there is still opportunity to grow. " +
          "Understanding the characteristics that you can develop further is important to " +
          "accelerating success and welcoming unintentional perfection."

        : "Your shine is above average. You likely have moments in your life " +
          "where shine is abundant. With a bit of work you can increase your sparks " +
          "and accelerate success by developing your shine to welcome unintentional " +
          "perfection."
    }

    if (shineScale <= 79 && shineScale >= 60) {
      return (emailVersion)
      ? "The amount of shine you’re creating is consistent with most people. " +
        "There are some characteristics that are sparking often to help create moments " +
        "of shine, but there’s opportunity to develop those that haven’t sparked yet. " +
        "Understanding and developing all characteristics of shine will support an " +
        "increase of your EQ (emotional quotient) and empathy skills, and help to " +
        "accelerate success and welcome unintentional perfection."

      : "The amount of shine you’re creating is consistent with most people. " +
        "However, your shine can easily be developed further to accelerate success, " +
        "but you need to increase the sparks you make and build your EQ and empathy " +
        "skills first."
    }

    if (shineScale >= 40 && shineScale <= 59) {
      return (emailVersion)
      ? "In order to shine you must spark. A spark has occurred before and another just " +
        "happened by taking The. Shine Scale™. With the opportunity you’ve discovered here, " +
        "if you focus on increasing the amount of sparks you make, you can accelerate " +
        "success and grow closer to the possibility of welcoming unintentional perfection. " +
        "When you do, you’ll enhance your EQ (emotional quotient) and empathy skills and " +
        "work your way towards becoming a high performer."

      : "A spark has occurred before and another just happened by taking The " +
        "Shine Scale™. Don’t stop now! Work on developing more sparks to increase " +
        "your shine, which will reward you with accelerated success and the " +
        "possibility of unintentional perfection."
    }

    if (shineScale <= 39) {
      return (emailVersion)
      ? "You must ignite sparks for them to happen and create moments of shine. " +
        "Don’t be discouraged by the opportunity you have discovered by taking The " +
        "Shine Scale™. Today you’re igniting the possibility to accelerate " +
        "success and welcome unintentional perfection. Each step you take towards " +
        "unlocking and unblocking your shine helps to develop the characteristics that " +
        "create sparks. When you spark more often, your shine will increase."

      : "You seeking to understand your shine is the first step is making it " +
        "spark more. By taking The Shine Scale™ you’re igniting the possibility " +
        "to accelerate success and welcome unintentional perfection. Spark more " +
        "often and you’ll start to increase your shine."
    }
  }

  static getOrientationStatement(mind: number, heart: number, _: boolean = false): string | undefined {
    if (mind < heart) { // Heart Oriented
      return "Most overall work needed is in the mind area. These characteristics " +
        "are more associated with how you’re applying your thought and focus in conversation " +
        "and experiences. You overall lead with your heart and the characteristics more associated " +
        "with emotions and energy. In many cases you likely have an opportunity to recognize " +
        "your natural characteristics that shine and lean into them more as you develop those " +
        "with the most opportunity.";
    }
    if (mind > heart){ // Mind Oriented
      return "Most overall work needed is in the heart area, which is where you " +
        "can further develop the characteristics more associated with emotions " +
        "and energy. You overall lead with your mind and the characteristics more " +
        "associated with how you’re applying your thought and focus in conversation " +
        "and experiences. In many cases you likely have an opportunity to recognize " +
        "your natural characteristics that shine and lean into them more as you " +
        "develop those with the most opportunity."
    }

    return undefined;
  }

  static getScoreRelatedText(sparkChange: boolean, score: IShineScore, emailVersion: boolean = false) {
    const { shineScale, hearth, mind, pointedGroups } = score
    dataOption(pointedGroups)

    return {
      shine_title: ScoringUtil.getShineTitle(shineScale),
      shine_score: `${Math.round(shineScale)}%`,
      heart_score: `${Math.round(hearth)}%`,
      mind_score: `${Math.round(mind)}%`,
      heart_oriented: (mind < hearth) ? "Heart Oriented!" : undefined,
      mind_oriented: (mind > hearth) ? "Mind Oriented!" : undefined,
      result_score_statement: ScoringUtil.getShineFeedbackParagraph(shineScale, emailVersion),
      orientation_statement: ScoringUtil.getOrientationStatement(mind, hearth),
      chart_points: ScoringUtil.getChartPoints(pointedGroups),
      mchartU: cookies.get('mchartU'),
      hchartU: cookies.get('hchartU'),
      cta_link: sparkChange ? "https://www.sparkchangeworkshop.com/" : "https://www.freeshinechallenge.com/shine-scale/"
    }
  }
}

export interface IAnswers {
  [key: string]: string
}

export interface IAnswerPoints {
  [key: string]: number
}

export interface IGroupPoints {
  [key: string]: number
}

export interface IShineScore {
  hearth: number
  mind: number
  shineScale: number
  pointedGroups: any
}

async function dataOption(chart_points) {
  const scores = ScoringUtil.getChartPoints(chart_points)
  mind.data.datasets[0].data.length = 0
  mind.data.datasets[0].data = Object.values(scores[0])
  heart.data.datasets[0].data.length = 0
  heart.data.datasets[0].data = Object.values(scores[1])

  const ChartJsImage = require('chartjs-to-image');

  
    const mindChart = new ChartJsImage();
    mindChart.setConfig({
      type: 'horizontalBar',
      data: mind.data,
      options: mind.options,
    });
    const mindChartURL = await mindChart.getShortUrl()
    cookies.set('mchartU', mindChartURL, {path : '/'})
  

    const heartChart = new ChartJsImage();
    heartChart.setConfig({
      type: 'horizontalBar',
      data: heart.data,
      options: heart.options,
    });
    const heartChartURL = await heartChart.getShortUrl()
    cookies.set('hchartU', heartChartURL, {path : '/'})
  
  console.log(cookies.get('mchartU'))
}