import Axios from "axios"
 
const MAIL_API_URL =
  "https://focused-bell-7d53fb.netlify.app/.netlify/functions/server/static-mailer"
 
const ZAPIER_API_URL =
  "https://hooks.zapier.com/hooks/catch/8358460/ovwd9nq"
export class MailingService {
  static sendMail(options: IMailOptions): Promise<any> {
    return Axios.post(MAIL_API_URL, options)
  }
  static sendZapierMail(options: IMailOptions): Promise<any> {
    var config = {    
      timeout: 7000,  
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    };
    return Axios.post(ZAPIER_API_URL, options,config)
  }
}


export interface IMailOptions {
  to: IRecipient[]
  bcc?: IRecipient[]
  cc?: IRecipient[]
  from: ISender
  reply_to: ISender
  subject?: string
  text?: string
  html?: string
  dynamicData: IMailDynamicData
}

export interface IMailDynamicData {
  shine_title: string
  shine_score: string
  heart_score: string
  mind_score: string
  heart_oriented: string
  mind_oriented: string
  result_score_statement: string
  mchartU: string
  hchartU: string
}

export interface IRecipient {
  email: string
  name?: string
}

export interface ISender extends IRecipient {}
