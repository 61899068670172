import * as React from "react"
import Button from "@material-ui/core/Button"
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core"

const AppButton = ({ classes, text, href, handleClick }: IProps) =>
  href ? (
    <a href={href} target="_blank" rel="noopener noreferrer" className={classes.link}>
    <Button
      className={classes.root}
      disableFocusRipple
      color="primary"
      size="medium"
    >
      {text || "Button"}
    </Button></a>
  ) : (
    <Button
      onClick={handleClick}
      className={classes.root}
      disableFocusRipple
      color="primary"
      size="medium"
    >
      {text || "Button"}
    </Button>
  )

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "black",
      margin: "20px auto",
      color: "#fbf5ad",
      minHeight: "40px",
      borderRadius: "3px",
      border: "0 none",
      fontWeight: 600,
      cursor: "pointer",
      letterSpacing: "0.1em",
      fontFamily: "Oswald, Helvetica",
      "&:hover": {
        backgroundColor: "black",
      },
    },
    link: {
      textDecoration: "none"
    }
  })

interface IProps extends WithStyles<typeof styles> {
  text: string
  handleClick?: () => any
  href?: string
}

export default withStyles(styles)(AppButton)
