import * as React from "react"

// @material-ui/core components
import { withStyles } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"

// Custom Components
import LandingStyles from "./Landing.styles"
import Grid from "@material-ui/core/Grid"

const useStyles = withStyles(LandingStyles as any)

const Landing = props => {
  const { classes, boxedContent } = props

  return (
    <Grid container className={classes.root}>
      <Grid container className={boxedContent ? classes.boxedContent : classes.content}>
        {props.children}
      </Grid>
      <div>
        <Divider className={classes.black} />
        <Divider className={classes.yellow} />
      </div>
    </Grid>
  )
}

export default useStyles(Landing)
