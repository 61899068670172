import * as seedRandom from "seed-random"
import { IQuestion } from "../typings"

export class RandomizeUtil {
  static shuffleArray(array: any[], seed?: any): IQuestion[] {

    const rand = (!seed) ? seedRandom() : seedRandom(seed);
    if (array.constructor !== Array) {
      throw new Error("Input is not an array")
    }

    let currentIndex = array.length, temporaryValue, randomIndex
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(rand() * currentIndex--)

      // And swap it with the current element.
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }

    return array as IQuestion[]
  }
}
