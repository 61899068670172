import { createStyles, Theme } from "@material-ui/core"

const QuizFormStyles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },

    shineScaleInfo: {
      fontFamily: 'Oswald'
    },

    form: {
      padding: 0,
      display: "flex",
      flexDirection: "column",
    },

    textField: {
      fontFamily: "Cursive"
    },

    modalContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "150px"
    },

    modalText: {
      marginTop: "10px",
      textAlign: "center"
    },

    scoreSection: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%"
    },

    shineIcon: {
      width: "10rem",
      height: "10rem",
      margin: 0,
      padding: "0.5em"
    },

    heartMindIcons: {
      width: "100px",
      height: "100px",
      margin: 0,
      padding: "0.5em"
    },

  })

export default QuizFormStyles
