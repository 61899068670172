import * as firebase from "firebase/app";
import env from "../../environment/env"
import { RandomizeUtil } from "../utils/randomize.util"
// @ts-ignore
import { IQuestion } from "../typings"
import { IUserState } from "../pages"

//TODO: Put this back in the environment, just putting this here for testing with AJ today
const firebaseConfig = env.firebaseConfig

// Firebase
require("firebase/auth")
require("firebase/database")

export class FirebaseService {
  private readonly _instance?: firebase.app.App

  constructor(appName) {
    if (this._instance) {
      return
    }
    this._instance = firebase.initializeApp(firebaseConfig, appName)
    firebase.auth(this._instance).signInAnonymously().catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log(errorCode + errorMessage)
    });
    firebase.auth(this._instance).onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        var isAnonymous = user.isAnonymous;
        var uid = user.uid;
        // ...
      } else {
        // User is signed out.
        // ...
      }
    });
  }

  get db() {
    return this._instance.database()
  }

  async getQuestions(): Promise<IQuestion[]> {
    try {
      // We are getting an snapshot of the questions as we don't need to listen for changes
      return this.db
        .ref("/questions")
        .once("value")
        .then(snapshot => {
          if (!snapshot) {
            return []
          }
          return RandomizeUtil.shuffleArray(Object.values(snapshot.val()))
        })
    } catch (err) {
      throw Error(err)
    }
  }

  async postQuizFormData(formData): Promise<IUserState> {
    try {
      const newKey = this.db
        .ref()
        .child("/users")
        .push().key

      const newRecord = {
        [newKey]: formData,
      }

      await this.db.ref("/users").update(newRecord)
      return {
        key: newKey,
        ...formData,
      }
    } catch (err) {
      throw Error(err)
    }
  }

  async updateUserData(data: IUserState): Promise<IUserState> {
    try {
      return this.db.ref(`/users/${data.key}`).update(data)
    } catch (err) {
      throw Error(err)
    }
  }
}
