import { withStyles } from "@material-ui/core"

export const feedbackUseStyles = withStyles({
  flexRoot: {
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  socialMediaSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem 0",
  },

  scoreSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },

  showGridOnDesktop: {
    display: "none"
  },

  feedbackTitle: {
    textAlign: "center",
    margin: "10px 0"
  },

  feedbackSubTitle: {
    fontWeight: 500,
    textAlign: "center",
  },

  feedBackText: {
    fontWeight: 300,
    textAlign: "center",
  },

  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    "& div.SocialMediaShareButton": {
      margin: "0 0.5rem",
    }
  },

  fbButton: {
    display: "flex",
    alignItems: "center",
    paddingRight: "1rem",
    borderRadius: "5px",
    color: "white",
    backgroundColor: "rgb(59, 89, 152)",
    cursor: "pointer"
  },

  twitterButton: {
    display: "flex",
    alignItems: "center",
    paddingRight: "1rem",
    borderRadius: "5px",
    color: "white",
    backgroundColor: "rgb(0, 172, 237)",
    cursor: "pointer"
  },

  linkedinButton: {
    display: "flex",
    alignItems: "center",
    paddingRight: "1rem",
    borderRadius: "5px",
    color: "white",
    backgroundColor: "#0072b1",
    cursor: "pointer"
  },

  whatsappButton: {
    display: "flex",
    alignItems: "center",
    paddingRight: "1rem",
    borderRadius: "5px",
    color: "white",
    backgroundColor: "rgb(44, 183, 66)",
    cursor: "pointer"
  },

  shineIcon: {
    width: "10rem",
    height: "10rem",
    margin: 0,
    padding: "0.5em"
  },

  heartMindIcons: {
    width: "100px",
    height: "100px",
    margin: 0,
    padding: "0.5em"
  },

  leadContainer: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "2px black solid",
    padding: "10px 0",
    margin: "20px auto"
  },

  yellowDiv: {
    backgroundColor: "#fbf5ad",
    // width: "100vw",
    margin: 0
  },

  leadList: {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  leadUnordered: {
    margin: 0,
    padding: "20px 0 0 0",
    listStyle: "none",
  },

  listItem: {
    padding: "5px 0"
  },

  diceText: {
    fontSize: "18px",
    margin: "10px 0 -5px 0"
  },

  "@media only screen and (min-width: 650px)": {
    hideGridOnDesktop: {
      display: "none"
    },

    showGridOnDesktop: {
      display: "flex"
    },
  }
})
