import { withStyles } from "@material-ui/core"

export const informationUseStyles = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },

  infoHeader: {
    marginBottom: "20px"
  },

  informationContainer: {
    margin: "20px 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },

  iconImage: {
    width: "30%",
    margin: "auto",
  },

  informationText: {
    margin: "10px 10px",
    textAlign: "center",
    fontSize: "1rem"
  },

  "@media (min-width: 768px)": {
    informationGrid: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "flex-start"
    },

    informationContainer: {
      maxWidth: "33%",
    },
  }
})
