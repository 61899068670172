import * as React from "react"
// @ts-ignore
import { IAnswer, IQuestion } from "../../../../typings"
import {
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core"
import { questionContentStyles } from "./QuestionContent.styles"

const QuestionContent = ({
  classes,
  question,
  value,
  handleChange,
}: {
  classes: any
  question: IQuestion
  value: any
  handleChange: any
}) => {
  if (!question) {
    return <div>Loading Questions...</div>
  }

  return (
    <Grid container className={classes.root}>
      <Grid className={classes.questionContainer}>
        <FormLabel component="legend" className={classes.questionText}>
          {question && question.displayText}
        </FormLabel>
      </Grid>
      <Grid className={classes.answerContainer}>
        <RadioGroup
          className={classes.answerRadioGroup}
          value={value || ""}
          onChange={handleChange(question.key)}
        >
          {question &&
            question.answers &&
            Object.entries(question.answers).map(
              ([key, value]: [string, IAnswer]) => (
                <FormControlLabel
                  className={classes.formControlLabel}
                  key={value.key}
                  value={value.key}
                  control={<Radio />}
                  label={
                    question.type === "image" ? (
                      <img className={classes.answerImage} src={value.url} />
                    ) : (
                      value.displayText
                    )
                  }
                />
              )
            )}
        </RadioGroup>
      </Grid>
    </Grid>
  )
}

export default questionContentStyles(QuestionContent)
