import * as React from "react"
import { informationUseStyles } from "./Information.styles"
import { Grid, Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import AppButton from "../shared/components/Button/AppButton"

const Information = ({ classes, handleNavigateNextSection }) => {
  const data = useStaticQuery(graphql`
    query {
      heartImage: file(relativePath: { eq: "shine-icons/heart-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mindImage: file(relativePath: { eq: "shine-icons/mind-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      shineImage: file(relativePath: { eq: "shine-icons/shine-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Grid className={classes.root}>
      <Typography variant="h4" color="textPrimary" className={classes.infoHeader}>This Quiz Shows How Much You’re Sparking</Typography>
      <Typography variant="body1" color="textPrimary" className={classes.infoHeader}>
        The results will show you where to create more sparks to improve your life and think bigger.  
      </Typography>
      <Typography variant="h5" color="textPrimary" className={classes.infoHeader}>Become the best version of you. Spark your imagination. Spark change.</Typography>
      <AppButton text="Start the Quiz!" handleClick={handleNavigateNextSection}/>
      <br/>
      <br/>
      <Typography variant="h4" color="textPrimary" className={classes.infoHeader}>The Method</Typography>
      <Typography variant="body1" color="textPrimary">
        The Shine Scale™ is 28 easy-to-answer questions that help show
        strengths and opportunities across the 14 characteristics of shine,
        which come from the heart and mind.
      </Typography>
      <Grid className={classes.informationGrid}>
        <Grid className={classes.informationContainer}>
          <Image
            fluid={data.heartImage.childImageSharp.fluid}
            className={classes.iconImage}
          />
          <Typography variant="body1" color="textPrimary" className={classes.informationText}>
            Aspects of you that are related more to your emotions and energy are
            connected to characteristics associated with your heart.
          </Typography>
        </Grid>
        <Grid className={classes.informationContainer}>
          <Image
            fluid={data.mindImage.childImageSharp.fluid}
            className={classes.iconImage}
          />
          <Typography variant="body1" color="textPrimary" className={classes.informationText}>
            Characteristics that impact thought and focus are those associated
            more with the mind and go beyond intellect and concentrate on application.
          </Typography>
        </Grid>
        <Grid className={classes.informationContainer}>
          <Image
            fluid={data.shineImage.childImageSharp.fluid}
            className={classes.iconImage}
          />
          <Typography variant="body1" color="textPrimary" className={classes.informationText}>
            When your heart and mind traits are fully engaged, you shine. Shining
            accelerates success. You radiate with a personality that is ignited
            by empathetic focus and strong emotional intelligence, creating an
            attraction to unintentional perfection.
          </Typography>
        </Grid>
      </Grid>
      <AppButton text="Start the Quiz!" handleClick={handleNavigateNextSection}/>
    </Grid>
  )
}

export default informationUseStyles(Information)
