import { withStyles } from "@material-ui/core"

export const questionContentStyles = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },

  questionContainer: {
    flex: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  questionText: {
    lineHeight: "1.4rem",
    marginBottom: "20px",
    color: "black",
    fontSize: "1.2rem",
  },

  answerContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  answerRadioGroup: {
    flex: 1,
    width: "90%",
    display: "flex",
    justifyContent: "space-around",
  },

  formControlLabel: {
    flexGrow: 1,
    width: "100%",
    margin: 0,
  },

  answerImage:{
    height: "19vh",
    width: "19vh",
  }
})
