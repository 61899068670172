import * as React from "react"
import Grid from "@material-ui/core/Grid"
import { feedbackUseStyles } from "./Feedback.styles"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { Typography, Container } from "@material-ui/core"
import { HorizontalBar } from "react-chartjs-2"
import { mind, heart } from "../../variables/charts"
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share"
// Icons
// @ts-ignore
import HeartIcon from "../../images/shine-icons/heart-icon.png"
// @ts-ignore
import MindIcon from "../../images/shine-icons/mind-icon.png"
// @ts-ignore
import Shine from "../../images/shine-icons/shine-icon.png"
import { ScoringUtil } from "../../utils/scoring.util"
import AppButton from "../shared/components/Button/AppButton"

const Feedback = props => {
  const { classes, userData, sparkChange } = props
  const {
    shine_title,
    shine_score,
    heart_score,
    mind_score,
    heart_oriented,
    mind_oriented,
    result_score_statement,
    chart_points,
    mchartU,
    hchartU,
  } = ScoringUtil.getScoreRelatedText(sparkChange, userData.score)

  const shareUrl = "https://theshinescale.com"
  const ctaLink = sparkChange ? "https://www.sparkchangeworkshop.com/" : "https://www.freeshinechallenge.com/shine-scale/"

  const mobileShineGrid = [
    classes.scoreSection,
    classes.hideGridOnDesktop,
  ].join(" ")
  const desktopShineGrid = [
    classes.scoreSection,
    classes.showGridOnDesktop,
  ].join(" ")

  const updateChart = () => {
    const scores = chart_points
    mind.data.datasets[0].data.length = 0
    mind.data.datasets[0].data = Object.values(scores[0])
    heart.data.datasets[0].data.length = 0
    heart.data.datasets[0].data = Object.values(scores[1])
  }

  updateChart()

  return (
    <div className={classes.flexRoot}>
      <Container className={classes.root}>
      { /*<Typography variant="body1" className={classes.feedbackSubTitle}>
          You're making a spark within yourself by being curious about your
          shine! Here are your results:
        </Typography>

  <Grid container className={classes.scoreSection}> */}
          {/*<Typography variant="h4" className={classes.feedbackTitle}>*/}
          {/*  {shine_title}*/}
          {/*</Typography>*/}
        {/*  <Grid className={mobileShineGrid}>
            <img className={classes.shineIcon} alt="Shine Icon" src={Shine} />
            <Typography variant="h6">Shine</Typography>
            <Typography variant="h3" className={classes.feedbackTitle}>
              {shine_score}
            </Typography>
          </Grid>
        </Grid>

        <Grid style={{ display: "flex" }}>
          <Grid className={classes.scoreSection}>
            <img
              className={classes.heartMindIcons}
              alt="Heart Icon"
              src={HeartIcon}
            />
            <Typography variant="h6">Heart</Typography>
            <Typography variant="h4" className={classes.feedbackTitle}>
              {heart_score}
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.feedbackSubTitle}
            >
              {heart_oriented}
            </Typography>
          </Grid>

          <Grid className={desktopShineGrid}>
            <img className={classes.shineIcon} alt="Shine Icon" src={Shine} />
            <Typography variant="h6">Shine</Typography>
            <Typography variant="h3" className={classes.feedbackTitle}>
              {shine_score}
            </Typography>
          </Grid>

          <Grid className={classes.scoreSection}>
            <img
              className={classes.heartMindIcons}
              alt="Heart Icon"
              src={MindIcon}
            />
            <Typography variant="h6">Mind</Typography>
            <Typography variant="h4" className={classes.feedbackTitle}>
              {mind_score}
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.feedbackSubTitle}
            >
              {mind_oriented}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="body1" className={classes.feedBackText}>
          <br />
          {result_score_statement}
        </Typography>
        <Container
          component={"div"}
          maxWidth={"md"}
          className={classes.leadContainer}
        >
          <Typography variant={"h5"}>
            Ready to improve your shine? And spark change in yourself?
          </Typography>
          <p className={classes.diceText}>It’s as simple as rolling the dice.</p>
          <AppButton
            href="https://shinestuff.getyourshine.com/collections/personalized-products/products/shine-dice%E2%84%A2?sa=D&ust=1602534359361000&usg=AFQjCNGiVSmrnbrdyzSz8x3v9meflOYO1Q"
            text={"Click Here to Find Out How"}
          />
        </Container> */ }
        <Typography variant="body1" className={classes.feedBackText}>
          <br />
          {/* Here are your detailed results on how you're sparking across the 14
          characteristics of shine.  */}
          Your biggest opportunity to increase your
          shine comes from those with the smallest yellow bars next to them.
        </Typography>
        <Typography
          variant="h4"
          color="textPrimary"
          className={classes.feedbackTitle}
        >
          Heart Results
        </Typography>
        <HorizontalBar data={heart.data} options={heart.options} />
        <Typography
          variant="h4"
          color="textPrimary"
          className={classes.feedbackTitle}
        >
          Mind Results
        </Typography>
        <HorizontalBar data={mind.data} options={mind.options} />
        <Container
          component={"div"}
          maxWidth={"md"}
          className={classes.leadContainer}
        >
          <Typography variant={"h5"}>
            Want to think bigger? Spark change today!
          </Typography>
          <p className={classes.diceText}>It’s as simple as rolling the dice.</p>
          <AppButton
            href="https://shinestuff.getyourshine.com/collections/personalized-products/products/shine-dice%E2%84%A2?sa=D&ust=1602534359361000&usg=AFQjCNGiVSmrnbrdyzSz8x3v9meflOYO1Q"
            text={"Click Here to Find Out How"}
          />
        </Container>
      </Container>
      <div className={classes.yellowDiv}>
        <Container component={"div"} maxWidth={"md"} className={classes.leadList}>
          <Typography variant={"h6"}>When you focus on building these characteristics, you can increase your shine, which helps you to:</Typography>
          <ul className={classes.leadUnordered}>
            <li className={classes.listItem}>1) Unlock & Unblock your imagination</li>
            <li className={classes.listItem}>2) Find more opportunities in life and at work</li>
            <li className={classes.listItem}>3) Be seen more</li>
            <li className={classes.listItem}>4) Find more success</li>
          </ul>
        </Container>
      </div>
      <Container>
        <Container
          component={"div"}
          maxWidth={"md"}
          className={classes.leadContainer}
        >
          <Typography variant={"h5"}>
            If you're ready to improve, spark change now!
          </Typography>
          <p className={classes.diceText}>It’s as simple as rolling the dice.</p>
          <AppButton
            href="https://shinestuff.getyourshine.com/collections/personalized-products/products/shine-dice%E2%84%A2?sa=D&ust=1602534359361000&usg=AFQjCNGiVSmrnbrdyzSz8x3v9meflOYO1Q"
            text={"Click Here to Find Out How"}
          />
        </Container>
        <Typography variant="body1" className={classes.feedBackText}>
          <br />
          Check your email for more information about your results and go to{" "}
          <OutboundLink
            href="https://www.getyourshine.com"
            target="_blank"
            rel="noopener"
          >
            www.getyourshine.com
          </OutboundLink>{" "}
          to engage more.
        </Typography>
        <Grid className={classes.socialMediaSection} component="div">
          <Container className={classes.buttonsContainer} maxWidth="sm">
            <FacebookShareButton
              className={classes.fbButton}
              url={shareUrl}
              quote={`I just took The Shine Scale™ and got ${shine_score}, ${shine_title}! Take the quiz and see if you are shining!`}
              hashtag="#theshinescale"
            >
              <FacebookIcon size={34} round={true} />
              Share
            </FacebookShareButton>

            <TwitterShareButton
              className={classes.twitterButton}
              url={shareUrl}
              title={`I just took The Shine Scale™ and got ${shine_score}, ${shine_title}! Take the quiz and see if you are shining!`}
              hashtags={["theshinescale", "shining"]}
            >
              <TwitterIcon size={34} round={true} />
              Tweet
            </TwitterShareButton>

            <LinkedinShareButton
              className={classes.linkedinButton}
              url={shareUrl}
              title={`I just took The Shine Scale™ and got ${shine_score}, ${shine_title}! Take the quiz and see if you are shining!`}
              hashtags={["theshinescale", "shining"]}
            >
              <LinkedinIcon size={34} round={true} />
              Share
            </LinkedinShareButton>

            <WhatsappShareButton
              className={classes.whatsappButton}
              url={shareUrl}
              title={`I just took The Shine Scale™ and got ${shine_score}, ${shine_title}! Take the quiz and see if you are shining!`}
              seperator=" "
            >
              <WhatsappIcon size={34} round={true} />
              Send
            </WhatsappShareButton>
          </Container>
        </Grid>
      </Container>
    </div>
  )
}

export default feedbackUseStyles(Feedback)
