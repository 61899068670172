import LazyLoadImage from "../LazyLoadImage/LazyLoadImage"

const LandingStyles = {
  root: {
    backgroundImage: { LazyLoadImage },
    backgroundSize: "auto",
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 220px)", //TODO: this is for desktop, missing mobile/tablet
    justifyContent: "space-between",
    alignItems: "center",
    color: "black",
  },
  content: {
    height: "100%",
    // padding: "1rem",
    boxSizing: "border-box",
  },
  boxedContent: {
    maxWidth: "1140px",
    padding: "1rem",
    boxSizing: "border-box",
  },
  black: {
    backgroundColor: "black",
    width: "100%",
    height: "10px",
  },
  yellow: {
    backgroundColor: "#fbf5ad",
    width: "100%",
    height: "10px",
  },
}

export default LandingStyles
