import * as React from "react"
import { useState } from "react"
import { withStyles } from "@material-ui/core/styles"
import QuizFormStyles from "./QuizForm.styles"
import { Dialog, TextField, Typography, Checkbox } from "@material-ui/core"
import AppButton from "../shared/components/Button/AppButton"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
// Icons
// @ts-ignore
import HeartIcon from "../../images/shine-icons/heart-icon.png"
// @ts-ignore
import MindIcon from "../../images/shine-icons/mind-icon.png"
// @ts-ignore
import Shine from "../../images/shine-icons/shine-icon.png"

const int_checkbk = []
const QuizForm = ({ classes, handleFormSubmit, firebaseSvc, company }) => {

  const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

  const [modalOpen, setModalOpen] = useState(false)

  const [showError, setShowError] = useState({
    email: false,
    firstName: false,
    lastName: false,
  })

  const [errorText, setErrorText] = useState({
    email: "",
    firstName: "",
    lastName: "",
  })

  const [formData, setFormData] = useState<any>({
    email: "",
    firstName: "",
    lastName: "",
  })

  const mobileShineGrid = [
    classes.scoreSection,
    classes.hideGridOnDesktop,
  ].join(" ")

  const desktopShineGrid = [
    classes.scoreSection,
    classes.showGridOnDesktop,
  ].join(" ")

  const handleInputChange = fieldName => event => {
    setFormData({
      ...formData,
      [fieldName]: event.target.value,
    })
  }

  const handleCheckboxChange = () => $event => {
    const value =
      $event && $event.target && $event.target.value ? $event.target.value : ""
      
    if($event.target.checked){
      int_checkbk.push(value);   
    }else{
      const index = int_checkbk.indexOf(value);
      if (index > -1) {
        int_checkbk.splice(index, 1);
      }      
    } 
    
  }

  const formValidationHandler = fieldName => event => {

    if (fieldName === "email") {
      if (!emailRegex.test(event.target.value)) {
        setErrorText({ ...errorText, email: "Please enter a valid email address" })
        setShowError({ ...showError, email: true })
      } else {
        setErrorText({ ...errorText, email: "" })
        setShowError({ ...showError, email: false })
      }
    } else {
      if (event.target.value === "" && ["email", "firstName", "lastName"].includes(fieldName)) {
        setErrorText({ ...errorText, [fieldName]: "This field can't be blank" })
        setShowError({ ...showError, [fieldName]: true })
      } else {
        setErrorText({ ...errorText, [fieldName]: "" })
        setShowError({ ...showError, [fieldName]: false })
      }
    }
  }

  React.useEffect(() => {
    if (company && company.length > 0) {
      setFormData(prevState => ({...prevState, company: company}))
    }
  }, [])

  const formSubmissionHandler = () => {
    if (formData.email != "" && emailRegex.test(formData.email) && formData.firstName != "" && formData.lastName != "") {
      firebaseSvc.postQuizFormData({ ...formData, interest:int_checkbk.join(', '), company: formData.company && formData.company.length > 0 ? formData.company : "N/A", industry: "N/A" })
        .then((storedUser) => handleFormSubmit(storedUser))
        .catch((err) => console.error(err))
    } else {
      setModalOpen(true)

      setErrorText({
        email: (!emailRegex.test(formData.email) || formData.email === "" || formData.email === null ? "Please enter a valid email address" : ""),
        firstName: (formData.firstName === "" || formData.firstName === null ? "This field can't be blank" : ""),
        lastName: (formData.lastName === "" || formData.lastName === null ? "This field can't be blank" : ""),
      })

      setShowError({
        email: (!emailRegex.test(formData.email) || formData.email === "" || formData.email === null),
        firstName: (formData.firstName === "" || formData.firstName === null),
        lastName: (formData.lastName === "" || formData.lastName === null),
      })

    }
  }

  const closeModalHandler = () => {
    setModalOpen(false)
  }

  return (
    <Container maxWidth="md" className={classes.root}>
      <Dialog open={modalOpen} onBackdropClick={closeModalHandler}>
        <Container className={classes.modalContainer}>
          <Typography variant="body1" color="textPrimary" className={classes.modalText}>There is an issue with your
            form, please check the fields and try again.</Typography>
          <AppButton text="Close" handleClick={closeModalHandler}/>
        </Container>
      </Dialog>
      <Typography variant="h6" className={classes.shineScaleInfo}>
        See where you’re sparking! Fill out the info below to get your results:
      </Typography>
      <Container className={classes.form}>
        {Object.keys(formData).map(fieldName => (
          <TextField
            key={fieldName}
            id={fieldName}
            label={fieldName.toUpperCase()}
            className={classes.textField}
            disabled={fieldName === "company"}
            type={fieldName === "email" ? "email" : "text"}
            required={fieldName === "email" || fieldName === "firstName" || fieldName === "lastName"}
            autoComplete={fieldName}
            value={formData[fieldName]}
            error={showError[fieldName]}
            helperText={errorText[fieldName]}
            onChange={handleInputChange(fieldName)}
            onBlur={formValidationHandler(fieldName)}
            margin="normal"
          />
        ))}
      </Container>
      <Typography variant="h6" className={classes.shineScaleInfo}>
        What Focus Area Interests You the Most?
      </Typography>
        
        <label>
        <Checkbox
          onChange={handleCheckboxChange()}
          value = {"I want to shine as a leader/business"}
        />
        <span>I want to shine as a leader/business</span>
        </label>
        <label>        
        <Checkbox
          onChange={handleCheckboxChange()}
          value={"I want my personal life to shine"}
        />
        <span>I want my personal life to shine</span>
        </label>
        <label>        
        <Checkbox
          onChange={handleCheckboxChange()}
          value={"I want to shine as a parent"}
        />
        <span>I want to shine as a parent</span>
        </label>
        <label>        
        <Checkbox
          onChange={handleCheckboxChange()}
          value={"I don’t want to choose/I want to be Shiny AF!"}
        />
        <span>I don’t want to choose/I want to be Shiny AF!</span>
        </label>
      <AppButton handleClick={formSubmissionHandler} text="LET'S GO!"/>

      {/*<Grid style={{ display: "flex" }}>*/}
      {/*  <Grid className={classes.scoreSection}>*/}
      {/*    <img*/}
      {/*      className={classes.heartMindIcons}*/}
      {/*      alt="Heart Icon"*/}
      {/*      src={HeartIcon}*/}
      {/*    />*/}
      {/*    <Typography variant="h6">Heart</Typography>*/}
      {/*    <Typography variant="h4" className={classes.feedbackTitle}>*/}
      {/*      ??*/}
      {/*    </Typography>*/}
      {/*  </Grid>*/}

      {/*  <Grid className={desktopShineGrid}>*/}
      {/*    <img className={classes.heartMindIcons} alt="Shine Icon" src={Shine} />*/}
      {/*    <Typography variant="h6">Shine</Typography>*/}
      {/*    <Typography variant="h4" className={classes.feedbackTitle}>*/}
      {/*      ??*/}
      {/*    </Typography>*/}
      {/*  </Grid>*/}

      {/*  <Grid className={classes.scoreSection}>*/}
      {/*    <img*/}
      {/*      className={classes.heartMindIcons}*/}
      {/*      alt="Heart Icon"*/}
      {/*      src={MindIcon}*/}
      {/*    />*/}
      {/*    <Typography variant="h6">Mind</Typography>*/}
      {/*    <Typography variant="h4" className={classes.feedbackTitle}>*/}
      {/*      ??*/}
      {/*    </Typography>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
    </Container>
  )
}

export default withStyles(QuizFormStyles)(QuizForm)
