import { withStyles } from "@material-ui/core"

export const questionsUseStyles = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  questionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: "center"
  },

  questionContent: {

  },

  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    "& div": {
      width: "33.3%",
      display: "flex",
      justifyContent: "center"
    }
  },

  button: {
    margin: "0 10px",
  },

  instructions: {
    marginTop: "10px",
    marginBottom: "10px",
  },
})
